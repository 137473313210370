.sideMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 75%;
  max-width: 350px;
  height: 100vh;
  background: var(--colors-surface-000);
  text-align: left;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100vw);
}

.sideMenu::-webkit-scrollbar {
  display: none;
}

.sideMenuOpen {
  transform: translateX(0);
}

.sideMenuButton {
  position: absolute;
  top: 24px;
  right: 24px;
  left: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
