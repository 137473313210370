.sideMenuNav {
  padding: 76px 0 24px;
  font-weight: 500;
  font-size: 16px;
}

.sideMenuNav a,
.sideMenuNavRow {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 18px;
  color: var(--colors-surface-800);
}

.sideMenuNavRow {
  color: var(--colors-surface-800);
  font-weight: 400;
  font-family: 'DM Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  opacity: 0.5;
}

.sideMenuNavSkeleton {
  padding: 10px 0 10px 18px;
}

.sideMenuNavCategories::before {
  content: '';
  display: block;
  height: 1px;
  margin: 12px 18px;
  background-color: var(--colors-secondary-300);
  font-size: 0;
}

.sideMenuNavCategory {
  padding-bottom: 18px;
  color: var(--colors-surface-800);
  font-size: 20px;
  line-height: 90%;
  letter-spacing: -0.02em;
}

.sideMenuNavCategoryDot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 10px 0 0;
  border-radius: 50%;
  background-color: var(--colors-surface-000);
}

.sideMenuNav a.sideMenuNavCategory {
  padding: 14px 0 18px 18px;
  color: var(--colors-surface-800);
}

@media (min-width: 768px) {
  .sideMenuNav {
    font-size: 16px;
  }

  .sideMenuNav a:hover,
  .sideMenuNav a:active {
    background-color: var(--colors-surface-700);
    color: var(--colors-surface-000);
  }

  .sideMenuNav a:active {
    box-shadow: inset 0 -2px 4px 5px rgba(0, 0, 0, 0.2);
  }

  .sideMenuNav a.sideMenuNavCategory:hover {
    background-color: var(--colors-surface-700);
    color: var(--colors-surface-000);
    transition: color 0.25s ease;
  }
}
